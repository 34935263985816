body {
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

html * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.5;
}

#root {
  background-color: #F9F9F9;
  position: relative;
  z-index: 1;
}
